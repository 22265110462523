import React, { Component } from 'react';
import { Menu, Segment, MenuItemProps, Grid, Image, List, Header, Divider } from 'semantic-ui-react';
import QRCode from 'qrcode.react';
import qs from 'query-string';
import './App.css';

const C = {
  about: {
    name: "about",
    text: "1️⃣ О приложении",
  },
  instructions: {
    name: "instructions",
    text: "2️⃣ Инструкция",
  },
  links: {
    name: "links",
    text: "3️⃣ Ссылки на приложение",
  },
  registration: {
    name: "registration",
    text: "4️⃣ Регистрация",
  },
}

const APPS = {
  ios: "https://itunes.apple.com/app/id1466651011",
  android: "https://play.google.com/store/apps/details?id=team.ggc.kanzitdinov.vaffanculoevotoradminapp",
}

const APP_NAME = "Чеки | Мотивация | Аналитика";

const SEARCH = {
  ios: `Откройте App Store и вбейте в поиске "${APP_NAME}"`,
  android: `Откройте Google Play и вбейте в поиске "${APP_NAME}"`,
}

const REG_SEPARATOR = "_!_";
const QRCODE_SIZE = 200;
const STORE_IMAGE_SIZE = "small";

class App extends Component {
  state = {
    token: '',
    user_id: '',
    activeItem: C.about.name, 
  }

  componentDidMount() {
    const url = window.location.search;
    const params = qs.parse(url);
    const token = params["token"];
    const user_id = params["user_id"];
    this.setState({ token, user_id });
  }

  handleItemClick = (e: React.MouseEvent<HTMLAnchorElement, MouseEvent>, data: MenuItemProps) => {
    this.setState({ activeItem: data.name })
  }

  handleNextTabClick = (nextTab: string) => {
    this.setState({ activeItem: nextTab })
  }

  render() {
    const {
      token,
      user_id,
      activeItem,
    } = this.state

    console.log(token, user_id);

    if (!token || !user_id) {
      return (
        <div className="App">
          <Header as="h4">Что-то тут не чисто...😏</Header>
        </div>
      )
    }

    return (
      <div className="App">
        <Menu pointing secondary>
          <Menu.Item 
            content={C.about.text}
            name={C.about.name}
            active={activeItem === C.about.name} 
            onClick={this.handleItemClick}
          />
          <Menu.Item 
            content={C.instructions.text}
            name={C.instructions.name}
            active={activeItem === C.instructions.name} 
            onClick={this.handleItemClick}
          />
          <Menu.Item
            content={C.links.text}
            name={C.links.name}
            active={activeItem === C.links.name} 
            onClick={this.handleItemClick}
          />
          <Menu.Item
            content={C.registration.text}
            name={C.registration.name}
            active={activeItem === C.registration.name} 
            onClick={this.handleItemClick}
          />
        </Menu>

        {
          activeItem === C.about.name ?
            <Segment>
              <div className="Div">
                <Header as='h2'>Спасибо, что скачали наше приложение 🤝</Header>
                <p className="P"><strong>"{APP_NAME}"</strong> позволяет в удобном виде просматривать подробную информацию о чеках вашего бизнеса и состоит из приложений под iOS и Android для непосредственно владельцев бизнеса, то есть вас, и для ваших работников.</p>
                <p className="P">Также с помощью него вы сможете наблюдать в виде соревнования, кто из ваших работников сделал больше выручки и даже ставить план для магазинов, по достижению которого для всех работников магазина будет начисляться бонус (по желанию).</p>
                <p className="P">А сейчас вам потребуется всего лишь 3 минуты, чтобы начать пользоваться приложением!</p>
                <p className="P">Перейдите во вкладку &nbsp; <span className="PLink" onClick={() => this.handleNextTabClick(C.instructions.name)}>[ {C.instructions.text} ]</span></p>
              </div>
            </Segment>
          : null
        }

        {
          activeItem === C.instructions.name ?
            <Segment>
              <div style={{ width: "80%", margin: "0 auto" }}>
                <p className="P">Поздравляем, с первым шагом вы уже справились - скачали приложение в Эвотор.Маркете 😅</p>
                <p className="P">Сейчас вам необходимо нажать на вкладку <span className="PLink" onClick={() => this.handleNextTabClick(C.links.name)}>[ {C.links.text} ]</span> и скачать приложение на ваш телефон. Не волнуйтесь, на кассы будет тоже установлено наше приложение, откуда ваши работники смогут скачать приложение таким же способом и следить за своими показателями в реальном времени.</p>
                <p className="P">Как только вы скачаете приложение, вам необходимо авторизоваться. Для этого необходимо открыть вкладку <span className="PLink" onClick={() => this.handleNextTabClick(C.registration.name)}>[ {C.registration.text} ]</span>, где будет QR-код, далее открыть приложение, нажать на появившемся экране кнопку "Авторизоваться", навести камеру на QR-код, который вы открыли ранее и ВУАЛЯ - готово, все просто и быстро!</p>
                <p className="P">Теперь можете пользоваться приложением, смотреть подробную информацию о чеках, выставлять планы для магазинов и поощрять своих сотрудников бонусами по достижению планов!</p>
                <p className="P">Если есть вопросы, пишите на почту - <strong>support@ggc.team</strong>, отвечаем всегда и почти сразу! Также это можно сделать и из настроек приложения 🙂</p>
              </div>
            </Segment>
          : null
        }

        {
          activeItem === C.links.name ?
            <Segment>
              <Grid>
                <Grid.Row columns={2}>
                  <Grid.Column>
                    <QRCode 
                      value={APPS.ios} 
                      size={QRCODE_SIZE} 
                    />
                    
                    <Divider horizontal>ИЛИ</Divider>
                    
                    <a href={APPS.ios} target="_blank">
                      <Image 
                        src={process.env.PUBLIC_URL + '/img/appStore.png'} 
                        size={STORE_IMAGE_SIZE} 
                        centered
                      />
                    </a>

                    <Divider horizontal>ИЛИ</Divider>

                    <Header as='h4'>{SEARCH.ios}</Header>
                  </Grid.Column>
                  <Grid.Column>
                    <QRCode
                      value={APPS.android}
                      size={QRCODE_SIZE}
                    />
                    
                    <Divider horizontal>ИЛИ</Divider>

                    <a href={APPS.android} target="_blank">
                      <Image 
                        src={process.env.PUBLIC_URL + '/img/googlePlay.png'} 
                        size={STORE_IMAGE_SIZE} 
                        centered
                      />
                    </a>

                    <Divider horizontal>ИЛИ</Divider>

                    <Header as='h4'>{SEARCH.android}</Header>
                  </Grid.Column>
                </Grid.Row>
              </Grid>
            </Segment>
          : null
        }

        {
          activeItem === C.registration.name ?
            <Segment>
              <QRCode 
                value={`${token}${REG_SEPARATOR}${user_id}`} 
                size={QRCODE_SIZE} 
              />
            </Segment>
          : null
        }
      </div>
    );
  }
}

export default App;
